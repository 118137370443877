// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~swiper/swiper-bundle';

@import 'font-montserrat.css';

ion-app {
    max-width: 1023px;
    margin: 0 auto;    
}

body {
    background-color: rgb(50,50,50);
}

:root {
  --ion-color-primary: #1C2541;
  --ion-color-primary-rgb: 218,226,37;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #6D82C0;
  --ion-color-primary-tint: #4B63AE;

  --ion-color-secondary: #EAEA1F;
  --ion-color-secondary-rgb: 12,209,232;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #F2F273;
  --ion-color-secondary-tint: #EFEF57;

  --ion-color-tertiary: #EFEF5E;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #282828;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #282828;
  --ion-color-medium-rgb: 103,103,103;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #5b5b5b;
  --ion-color-medium-tint: #767676;

  --ion-color-light: #F5F5F5;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// .action-sheet-container .action-sheet-group:nth-child(1) .action-sheet-button:nth-child(2) .action-sheet-icon {
// 	color: red;
// }

// .action-sheet-container .action-sheet-group:nth-child(1) .action-sheet-button:nth-child(3) .action-sheet-icon {
// 	color: yellow;	
// }

.item-inner p {
	color: rgb(140,140,140);
}

.button-full {
	margin-top: 20px;
	--height: 48px;
}

.selectAction .action-sheet-group-cancel {
	border-top: 1px solid rgb(160,160,160);
}

.selectAction .action-sheet-group-cancel .action-sheet-button-inner {
	color: rgb(150,150,150);
}

.item-svd {
  ion-select {
    border: 1px solid rgb(160,160,160);
    --padding-top: 5px;
    --padding-end: 0;
    --padding-bottom: 5px;
    --padding-start: 5px;
  }
}

// .sc-ion-alert-md-h {
// 	width: 95% !important;
// 	min-width: 320px !important;
// 	max-width: 700px !important;
// }
